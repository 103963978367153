import axios from 'axios'
import Vue from 'vue/dist/vue.js'
import Swal from 'sweetalert2'

new Vue({
    el: '#OpenedCart',
    data: {
        Products: []
    },
    methods: {

        MoneyConvert(int) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              
                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
              });
              return formatter.format(int)
        }, 
        getProducts() {
            axios.get('/web/api/cart/get')
                .then(response => {
                    this.Products = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                })  
        },
        sumarProducto(item) {
            if((item.Quantity < item.Stock)) {
                item.Quantity += 1;

                axios.post("/web/api/cart/modify", {id: item.CartId, Quantity: item.Quantity}).then(function(response){
                    if(response.data.error) {
                        alert(response.data.mensaje);
                    }
                });    
            }
            else if(item.IsPromo && item.Promo_Stock > 0) {
                if(item.Quantity < item.Promo_Stock) {
                    item.Quantity += 1;

                    axios.post("/web/api/cart/modify", {id: item.CartId, Quantity: item.Quantity}).then(function(response){
                        if(response.data.error) {
                            alert(response.data.mensaje);
                        }
                    });    
                }
            }
        },
        restarProducto(item) {
            if(item.Quantity > 1) {
                item.Quantity -= 1;
                
                axios.post("/web/api/cart/modify", {id: item.CartId, Quantity: item.Quantity}).then(function(response){
                    if(response.data.error) {
                        alert(response.data.mensaje);
                    }
                });    
            }
        },
        eliminarProducto(item) {
            let self = this;
            axios.post("/web/api/cart/del", {id: item.CartId}).then(function(response) {
                if(response.data.error){
                    alert(response.data.mensaje);
                } else {
                    const index = self.Products.map(object => object.CartId).indexOf(item.CartId);
                    self.Products.splice(index,  1);

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino producto del carrito.',
                        showConfirmButton: false,
                        timer: 1000
                        })
                }
            });
        },
        validate() {
            var error = false;
            Object.entries(this.Products).forEach(([key, val]) => {
                if(val.Stock == 0 && (val.Promo_Stock == 0 || val.Promo_Stock == null)) {
                    error = true;
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        showDenyButton: true,
                        html: 'Parece que el siguiente producto se quedó sin Stock:<br><br>' + val.Title + '<br><br>¿Que deseas hacer?',
                        confirmButtonText: 'Eliminar',
                        denyButtonText: `Cancelar`
                    }).then((result) => {
                        if (result.isConfirmed) {
                          this.eliminarProducto(val);
                        }
                    })
                }
            })
            if(!error)
                location.href = "/compras/confirmaCompra"
        },
        total: function() {
            let total = [];
            Object.entries(this.Products).forEach(([key, val]) => {
                if(val.Promo_Stock > 0)
                    total.push(parseFloat(val.Promo_Price * val.Quantity))
                else
                    total.push(parseFloat(val.Price * val.Quantity))
            });
            return total.reduce(function(total, num){ return total + num }, 0);
        } 
    },
    created() {
        this.getProducts();
    }
})